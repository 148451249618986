import React from "react"
import ctaBackground from "../images/Dark Gradient 2.svg"
import HeadlessCTA from "../components/headlessCTA"
import LogoWall from "../components/LogoWall"

const CTA = () => {
  return (
    <section
      style={{
        background: `url("${ctaBackground}") no-repeat top center`,
        backgroundSize: "2000px",
      }}
      className="container sm:rounded-lg mx-auto"
    >
      <div
        className="text-center flex flex-col items-center gap-8 pt-24 pb-8 max-w-4xl mx-auto"
        id="hero-container"
      >
        <div
          className="font-bold text-white text-5xl lg:text-7xl"
          style={{
            mixBlendMode: "overlay",
            fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
          }}
        >
          Ready to see Tactic in action?
        </div>
        <div
          className="text-medium-grey text-xl md:text-2xl"
          style={{ fontFeatureSettings: "'salt' on" }}
        >
          Book a personalised demo of Tactic
        </div>
        <HeadlessCTA />
        <LogoWall />
      </div>
    </section>
  )
}

export default CTA
