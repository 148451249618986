import { IconCircleCheck } from "@tabler/icons"
import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import React, { useState } from "react"

const HeadlessCTA = ({
  buttonLabel = "Request Demo",
  source = "Tactic Landing Page",
  nextPage = null,
  mode = "dark",
}) => {
  const [email, setEmail] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSignup, setIsSignup] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email, {
      FULLNAME: source,
      MMERGE3: `headless-cta-${window.location.href}`,
      FUNCTION: "Sales, Marketing, or Growth",
    })
      .then(data => {
        if (data.result === "error") {
          alert(data.msg)
          console.log(data)
        }
        if (data.result === "success") {
          setIsSuccess(true)
          if (nextPage) {
            window.setTimeout(() => {
              window.location.href = nextPage
            }, 500)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const formCls =
    mode === "dark"
      ? "flex flex-col items-stretch sm:flex-row gap-2 border rounded-lg border-white/25 bg-black/25 shadow-xl p-2 w-full sm:w-fit hover:border-indigo-200 transition"
      : "flex flex-col items-stretch sm:flex-row gap-2 border-2 rounded-lg border-mid-grey shadow-lg p-2 w-full sm:w-fit hover:border-indigo-200 transition"

  const inputCls =
    mode === "dark"
      ? "bg-transparent text-white md:pl-3 sm:w-80 md:w-96 leading-none focus:outline-none"
      : "bg-transparent md:pl-3 sm:w-80 md:w-96 leading-none focus:outline-none"

  return (
    <>
      {isSignup ? (
        <div className="flex flex-col md:flex-row gap-4">
          <Link
            className=" bg-lilac hover:bg-lilac-dark py-3 px-4 shadow-button text-white hover:text-white text-lg 
            rounded-lg leading-none cursor-pointer transition whitespace-nowrap"
            to="https://app.tactic.fyi/?screen_hint=signup"
            rel="nofollow"
          >
            Start free now &rarr;
          </Link>
          <div
            className="bg-white/40 hover:bg-white/50 py-3 px-4 shadow-button text-light-grey text-lg
            rounded-lg leading-none cursor-pointer transition whitespace-nowrap"
            onClick={() => setIsSignup(false)}
          >
            Contact Sales
          </div>
        </div>
      ) : !isSuccess ? (
        <form onSubmit={handleSubmit} className={formCls}>
          <input
            className={inputCls}
            placeholder="Your work email"
            onChange={e => setEmail(e.currentTarget.value)}
          ></input>

          <button
            type="submit"
            className="
            bg-white py-1 lg:py-3 px-4 shadow-xl text-black hover:text-blue
            rounded-lg leading-none cursor-pointer transition whitespace-nowrap
            border border-transparent hover:border-blue"
          >
            {buttonLabel}
          </button>
        </form>
      ) : (
        <SuccessMessage nextPage={nextPage} />
      )}
    </>
  )
}

const SuccessMessage = ({ nextPage }) => {
  if (nextPage === null) {
    return (
      <div className="text-traffic-green flex gap-2 items-center leading-none">
        <IconCircleCheck />
        Thanks! We'll be in touch very soon to set up a demo.
      </div>
    )
  } else {
    return (
      <div className="text-green-200 flex gap-2 items-center leading-none">
        <IconCircleCheck />
        <p className="whitespace-nowrap">
          Thanks! Redirecting to the full dataset now.
        </p>
      </div>
    )
  }
}

export default HeadlessCTA
